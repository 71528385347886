import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import ApplicationLayout from 'components/layouts/preliminary-application-layout'
import { usePreliminaryApplication } from 'contexts/preliminary-application-context'
import ContainedButton from 'components/buttons/contained-button'

export default ({ location }) => {
  const { submit, submitError, initialized } = usePreliminaryApplication()
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    const startSubmit = async () => {
      setLoading(true)
      try {
        setSubmitting(true)
        await submit()
        setSubmitSuccess(true)
        setSubmitted(true)
      } catch (error) {
        if (error.message) window.alert(error.message)
        else window.alert('Something went wrong!')
      }
      setLoading(false)
      setSubmitting(false)
    }

    if (initialized && !submitted) {
      startSubmit()
    }
  }, [initialized])

  const handleStartApplication = () => navigate('/preliminary-application')

  const handleReviewClick = () =>
    navigate('/preliminary-application/personal-information')

  if (!initialized) {
    return <div className="flex items-center justify-center">Loading...</div>
  }

  return (
    <ApplicationLayout location={location}>
      <div className="my-10 flex flex-col items-center px-4">
        {loading ? (
          <p className="mb-6 text-center">Loading...</p>
        ) : submitting ? (
          <>
            <p className="mb-6 text-center">Submitting</p>
            <p>Please do not close or refresh the browser window.</p>
          </>
        ) : submitError ? (
          <div className="flex flex-col items-center">
            <p className="mb-6">
              There are errors in your application. Please review your
              application and resubmit.
            </p>

            <ContainedButton onClick={handleReviewClick}>
              Review
            </ContainedButton>
          </div>
        ) : submitSuccess ? (
          <>
            <p className="mb-6 text-center">You application is submitted</p>
            <p>You may close this window.</p>
          </>
        ) : (
          <div>
            <p>Start your application</p>
            <div className="flex flex-row justify-center">
              <ContainedButton onClick={handleStartApplication}>
                Start
              </ContainedButton>
            </div>
          </div>
        )}
      </div>
    </ApplicationLayout>
  )
}
